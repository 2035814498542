import { useDispatch, useSelector } from 'react-redux';
import Action from '../redux/Action';
import { setInputType } from '../redux/Reducer';
import { useState } from 'react';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const FormInput = () => {
    const dispatch = useDispatch();
    const FILTER = [0, 1, 2, 3, 4];
    const inputType = useSelector(state => state.inputType);
    const isLoading = useSelector(state => state.isLoading);
    const [slider, setSlider] = useState(null);
    const [illustration, setIllustration] = useState(null);

    return (
        <div className="right_content">
            <div className="wrapper_right">
                <label htmlFor="filter_service">Filter Service</label>
                <select
                    id="filter_service"
                    name="filter_service"
                    onChange={(e) => {
                        const value = e.target.value;
                        const type = value === "" ? null : parseInt(value);
                        dispatch(Action.getData({ page: 0, pageSize: 6, type }));
                    }}>
                    <option value="">All</option>
                    <option value={0}>Streetwear</option>
                    <option value={1}>Illustration</option>
                    <option value={2}>Branding</option>
                    <option value={3}>Marketing</option>
                    <option value={4}>Preview Premade</option>
                </select>
                <div className="divider"></div>
                <form encType='multipart/form-data' onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData();
                    formData.append("slider", slider);
                    formData.append("type", inputType === "" ? null : inputType);
                    formData.append("illustration", illustration);
                    dispatch(Action.createItem(formData, setSlider, setIllustration));
                }}>

                    <label htmlFor="service">Service</label>
                    <select
                        id="service"
                        name="service"
                        onChange={(e) => {
                            const value = e.target.value;
                            const type = value === "" ? null : parseInt(value);
                            dispatch(setInputType(type));
                        }}
                        value={inputType}>
                        <option value="">Choose</option>
                        <option value={0}>Streetwear</option>
                        <option value={1}>Illustration</option>
                        <option value={2}>Branding</option>
                        <option value={3}>Marketing</option>
                        <option value={4}>Preview Premade</option>
                    </select>

                    <label htmlFor="main">Landscape Pic</label>
                    <input
                        type="file"
                        id="slider"
                        name="slider"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setSlider(file);
                        }}
                        accept=".jpg, .png" />

                    {
                        inputType === 3 || inputType === 4 ? "" : (
                            <>
                                <label htmlFor="illustration">Square Pic</label>
                                <input
                                    type="file"
                                    id="illustration"
                                    name="illustration"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        setIllustration(file);
                                    }}
                                    accept=".jpg, .png" />
                            </>
                        )
                    }

                    <button
                        type="submit"
                        className={!slider || !FILTER.includes(inputType) || isLoading ? "disabled" : ""}
                        disabled={!slider || !FILTER.includes(inputType) || isLoading}>
                        {
                            isLoading ?
                                <img className='loading_gif' src={IMAGE_URL + "/loading.svg"} alt="" />
                                : "SUBMIT"
                        }
                    </button>
                </form>
            </div>
        </div >
    );
};

export default FormInput;