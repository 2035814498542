import React, { useEffect } from 'react';
import BoxItem from './BoxItem';
import Action from '../redux/Action';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import Pagination from '../../../components/Pagination';

const TypeConstant = [
    "streetwear",
    "illustration",
    "branding",
    "marketing",
    "preview premade"
];

const Content = () => {
    const dispatch = useDispatch();
    const serviceData = useSelector(state => state.data);
    const currentPage = useSelector(state => state.currentPage);
    const totalPages = useSelector(state => state.totalPages);
    const type = useSelector(state => state.type);

    useEffect(() => {
        dispatch(Action.getData({ page: currentPage, pageSize: 6, type }));
    }, []);

    const handleDeleteItem = async (service) => {
        try {
            const deleted = await swal({
                icon: "warning",
                title: "Peringatan",
                text: "Apakah yakin untuk menghapus ?",
                buttons: ["Cancel", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false
            });
            if (deleted) {
                const id = service.id;
                dispatch(Action.deleteItem(id));
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
        }
    };

    return (
        <div className="left_content">
            <div className="box_wrapper">
                {serviceData.map((service) => {
                    const imageSlider = [];
                    imageSlider.push(service.slider);
                    if (service.illustration) {
                        imageSlider.push(service.illustration);
                    }
                    return <BoxItem
                        key={service.id}
                        type={TypeConstant[service.type].toUpperCase()}
                        slider={imageSlider}
                        onDelete={() => handleDeleteItem(service)} />;
                })}
            </div>
            {
                serviceData.length > 0 ? (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPrev={() => {
                            dispatch(Action.handlePrev(currentPage, type));
                        }}
                        onNext={() => {
                            dispatch(Action.handleNext(currentPage, type));
                        }} />
                ) : ""
            }
        </div>
    );
};

export default Content;