import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

const generateStore = (reducer, ignoredActionPaths) => {
    return configureStore({
        reducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActionPaths
                },
            }),
    });
};

const StoreHelper = {
    generateStore
};

export default StoreHelper;