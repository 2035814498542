import { createSlice } from "@reduxjs/toolkit";

export const reducerSlice = createSlice({
    name: "ServiceReducer",
    initialState: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalData: 0,
        type: null,
        inputType: "",
        isLoading: false
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalData: (state, action) => {
            state.totalData = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setInputType: (state, action) => {
            state.inputType = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

export const {
    setData,
    setCurrentPage,
    setTotalPages,
    setTotalData,
    setType,
    setInputType,
    setLoading
} = reducerSlice.actions;

export default reducerSlice.reducer;