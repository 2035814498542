import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
    name: "StatisticsReducer",
    initialState: {
        data: {
            projects: 0,
            clients: 0,
            ratings: 0,
            reviews: 0
        }
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const {
    setData
} = reducerSlice.actions;

export default reducerSlice.reducer;