import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const STATIC_IMAGE = process.env.REACT_APP_IMAGE_URL;
const SERVER_IMAGE = process.env.REACT_APP_SERVER_URL;

const BoxItem = ({ type, onDelete, slider }) => {
    return (
        <div className="box_item">
            <div className='slider'>
                {
                    slider.length <= 1 ? (
                        <img src={`${SERVER_IMAGE}/services/${slider[0]}`} alt="" style={{ width: "100%", objectFit: "cover", height: "100%" }} />
                    ) : (
                        <Splide
                            aria-label=""
                            options={{
                                rewind: true,
                                width: "100%",
                                height: '244px'
                            }}>
                            {slider.map((s, index) => (
                                <SplideSlide key={index}>
                                    <img src={`${SERVER_IMAGE}/services/${s}`} alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                </SplideSlide>
                            ))}
                        </Splide>
                    )
                }
            </div>
            <div className="desc_bar">
                <div className="label">
                    {type}
                </div>
                <img src={STATIC_IMAGE + "/trash2.svg"} className="trash" alt="" onClick={() => onDelete()} />
            </div>
        </div>
    );
};

export default BoxItem;
