import axios from "axios";
import Endpoint from "../../../service/EndPoint";
import { setData } from "./Reducer";
import Cookies from "js-cookie";
import swal from "sweetalert";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getData = () => {
    return async (dispatch) => {
        try {
            const reply = await axios.get(SERVER_URL + Endpoint.statistics.get);
            const data = reply.data.data;

            dispatch(setData(data));
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const updateData = (payload) => {
    return async (dispatch) => {
        try {
            const token = Cookies.get('token');
            const headers = {
                Authorization: `Bearer ${token}`
            };
            const reply = await axios.put(SERVER_URL + Endpoint.statistics.update, payload, { headers });
            const isSuccess = reply.data.success;

            if (isSuccess) {
                const isSwal = await swal({
                    icon: "success",
                    title: "Sukses",
                    text: `Berhasil merubah data`,
                    closeOnClickOutside: false
                });

                if (isSwal) {
                    dispatch(Action.getData());
                }
            }
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const Action = {
    getData,
    updateData
};

export default Action;