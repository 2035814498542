import axios from "axios";
import { setCurrentPage, setData, setInputType, setLoading, setTotalPages } from "./Reducer";
import Endpoint from "../../../service/EndPoint";
import swal from "sweetalert";
import Cookies from "js-cookie";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getData = (payload) => {
    return async (dispatch) => {
        try {
            const reply = await axios.post(SERVER_URL + Endpoint.service.get, payload);
            const data = reply.data.data;
            const currentPage = reply.data.currentPage;
            const totalPages = reply.data.totalPages;

            dispatch(setData(data));
            dispatch(setCurrentPage(currentPage));
            dispatch(setTotalPages(totalPages));
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const createItem = (payload, setSlider, setIllustration) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const token = Cookies.get('token');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            };
            const reply = await axios.post(SERVER_URL + Endpoint.service.create, payload, { headers });
            const isSuccess = reply.data.success;

            if (isSuccess) {
                const isSwal = await swal({
                    icon: "success",
                    title: "Sukses",
                    text: `Berhasil menambahkan data!`,
                    closeOnClickOutside: false
                });

                if (isSwal) {
                    dispatch(Action.getData({ page: 0, pageSize: 6, type: null }));
                    setIllustration(null);
                    setSlider(null);
                    dispatch(setInputType(""));
                    dispatch(setLoading(false));
                }
            }
        } catch (error) {
            const message = error.response.data.message;
            swal({
                icon: "error",
                title: "Gagal",
                text: message,
                closeOnClickOutside: false
            });
        }
    };
};

const deleteItem = (id) => {
    return async (dispatch) => {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            };
            await axios.delete(SERVER_URL + Endpoint.service.delete + "/" + id, { headers });

            const isSwal = await swal({
                icon: "success",
                title: "Sukses",
                text: `Berhasil menghapus data`,
                closeOnClickOutside: false
            });

            if (isSwal) {
                dispatch(Action.getData({ page: 0, pageSize: 6, type: null }));
            }
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const handlePrev = (currentPage, type) => {
    return (dispatch) => {
        try {
            dispatch(setCurrentPage(currentPage - 1));
            const updatedPage = currentPage - 1;

            dispatch(Action.getData({ page: updatedPage, pageSize: 6, type }));
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const handleNext = (currentPage, type) => {
    return (dispatch) => {
        try {
            dispatch(setCurrentPage(currentPage + 1));
            const updatedPage = currentPage + 1;

            dispatch(Action.getData({ page: updatedPage, pageSize: 6, type }));
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const Action = {
    getData,
    deleteItem,
    createItem,
    handlePrev,
    handleNext
};

export default Action;