const cutString = (inputString, maxLength) => {
    if (inputString.length > maxLength) {
        return inputString.slice(0, maxLength) + "...";
    } else {
        return inputString;
    }
};

const GeneralUtility = {
    cutString
};

export default GeneralUtility;