import axios from "axios";
import Endpoint from "../../../service/EndPoint";
import { setCurrentPage, setData, setForm, setInputImage, setLoading, setMode, setTotalPages } from "./Reducer";
import Cookies from "js-cookie";
import swal from "sweetalert";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getData = (payload) => {
    return async (dispatch) => {
        try {
            const reply = await axios.post(SERVER_URL + Endpoint.product.getAll, payload);
            const data = reply.data.data;
            const currentPage = reply.data.currentPage;
            const totalPages = reply.data.totalPages;

            dispatch(setData(data));
            dispatch(setCurrentPage(currentPage));
            dispatch(setTotalPages(totalPages));
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const createItem = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const token = Cookies.get('token');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            };
            const reply = await axios.post(SERVER_URL + Endpoint.product.create, payload, { headers });
            const isSuccess = reply.data.success;

            if (isSuccess) {
                const isSwal = await swal({
                    icon: "success",
                    title: "Sukses",
                    text: `Berhasil menambahkan data!`,
                    closeOnClickOutside: false
                });

                if (isSwal) {
                    const filter = {
                        searchBy: "name",
                        searchValue: "",
                        sortBy: "name",
                        sortAsc: true,
                        showInactive: false,
                        minPrice: 0,
                        maxPrice: 100
                    };
                    dispatch(Action.getData({ page: 0, pageSize: 6, filter }));
                    dispatch(setForm({
                        id: 0,
                        name: "",
                        price: 0,
                        promoPrice: 0,
                        linkGumroad: "",
                        images: []
                    }));
                    dispatch(setInputImage(null));
                    dispatch(setLoading(false));
                }
            }
        } catch (error) {
            const message = error.response.data.message;
            swal({
                icon: "error",
                title: "Gagal",
                text: message,
                closeOnClickOutside: false
            });
        }
    };
};

const deleteItem = (id) => {
    return async (dispatch) => {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            };
            await axios.delete(SERVER_URL + Endpoint.product.delete + "/" + id, { headers });

            const isSwal = await swal({
                icon: "success",
                title: "Sukses",
                text: `Berhasil menghapus data`,
                closeOnClickOutside: false
            });

            if (isSwal) {
                const filter = {
                    searchBy: "name",
                    searchValue: "",
                    sortBy: "name",
                    sortAsc: true,
                    showInactive: false,
                    minPrice: 0,
                    maxPrice: 100
                };
                dispatch(Action.getData({ page: 0, pageSize: 6, filter }));
            }
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const changeStatus = (payload, filter) => {
    return async (dispatch) => {
        try {
            const token = Cookies.get('token');
            const headers = {
                Authorization: `Bearer ${token}`
            };
            const reply = await axios.put(SERVER_URL + Endpoint.product.updateStatus, payload, { headers });
            const isSuccess = reply.data.success;

            if (isSuccess) {
                const isSwal = await swal({
                    icon: "success",
                    title: "Sukses",
                    text: `Berhasil merubah status data`,
                    closeOnClickOutside: false
                });

                if (isSwal) {
                    // const filter = {
                    //     searchBy: "name",
                    //     searchValue: "",
                    //     sortBy: "name",
                    //     sortAsc: true,
                    //     showInactive: false,
                    //     minPrice: 0,
                    //     maxPrice: 100
                    // };
                    // dispatch(setFilter(filter));
                    dispatch(Action.getData({ page: 0, pageSize: 6, filter }));
                }
            }
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const handlePrev = (currentPage, filter) => {
    return (dispatch) => {
        try {
            dispatch(setCurrentPage(currentPage - 1));
            const updatedPage = currentPage - 1;
            dispatch(Action.getData({ page: updatedPage, pageSize: 6, filter }));
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const handleNext = (currentPage, filter) => {
    return (dispatch) => {
        try {
            dispatch(setCurrentPage(currentPage + 1));
            const updatedPage = currentPage + 1;

            dispatch(Action.getData({ page: updatedPage, pageSize: 6, filter }));
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const getDetailData = (productId) => {
    return async (dispatch) => {
        try {
            const token = Cookies.get('token');
            const headers = {
                Authorization: `Bearer ${token}`
            };

            const reply = await axios.get(SERVER_URL + Endpoint.product.detail + "/" + productId, { headers });
            const product = reply.data;
            const form = {
                id: productId,
                name: product.data.name,
                price: product.data.price,
                promoPrice: product.data.promo_price,
                linkGumroad: product.data.link,
                images: product.data.images
            };
            dispatch(setForm(form));
            dispatch(setMode("edit"));
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const updateItem = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const token = Cookies.get('token');
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            };

            const reply = await axios.post(SERVER_URL + Endpoint.product.update, payload, { headers });
            const isSuccess = reply.data.success;
            if (isSuccess) {
                const isSwal = await swal({
                    icon: "success",
                    title: "Sukses",
                    text: `Berhasil merubah status data`,
                    closeOnClickOutside: false
                });

                if (isSwal) {
                    const filter = {
                        searchBy: "name",
                        searchValue: "",
                        sortBy: "name",
                        sortAsc: true,
                        showInactive: false,
                        minPrice: 0,
                        maxPrice: 100
                    };
                    dispatch(Action.getData({ page: 0, pageSize: 6, filter }));
                    dispatch(setMode("add"));
                    dispatch(setForm({
                        id: 0,
                        name: "",
                        price: 0,
                        promoPrice: 0,
                        linkGumroad: "",
                        images: []
                    }));
                    dispatch(setLoading(false));
                }
            }
        } catch (error) {
            console.log("An error occurred: " + error.message);
        }
    };
};

const Action = {
    getData,
    handlePrev,
    handleNext,
    deleteItem,
    createItem,
    changeStatus,
    getDetailData,
    updateItem
};

export default Action;