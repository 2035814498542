import React from 'react';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const Header = () => {
    return (
        <div className="header_logo">
            <img src={IMAGE_URL + "/logo.svg"} alt="" />
        </div>
    );
};

export default Header;