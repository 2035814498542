import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RouteName from '../../service/RouteName';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../app/redux/Reducer';
import Action from "../app/redux/Action";
import Cookies from 'js-cookie';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.isLoading);
    const form = useSelector(state => state.form);

    useEffect(() => {
        const isTokenAvailable = Cookies.get('token');
        if (isTokenAvailable) {
            return navigate(RouteName.service, { replace: true });
        }
    }, []);

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            dispatch(Action.login(form, navigate));
        }}>
            <div className='login_wrapper'>
                <div className='box_login'>
                    <img className="logo" src={IMAGE_URL + "/logo.svg"} alt="" />
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        onChange={(e) => {
                            const value = e.target.value;
                            dispatch(setForm({ ...form, username: value }));
                        }} />
                    <label htmlFor="username">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => {
                            const value = e.target.value;
                            dispatch(setForm({ ...form, password: value }));
                        }} />
                    <button
                        type='submit'
                        className={!form.username || !form.password ? "disabled" : ""}
                        disabled={!form.username || !form.password}>
                        {isLoading ? (
                            <img className='loading_gif' src={IMAGE_URL + "/loading.svg"} alt="" />
                        ) : "LOGIN"}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default Login;