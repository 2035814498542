const Endpoint = {
    product: {
        create: "/api/product/create",
        delete: "/api/product/delete",
        getAll: "/api/product/get-all",
        updateStatus: "/api/product/change-status",
        detail: "/api/product",
        update: "/api/product/update"
    },
    service: {
        get: "/api/service",
        getSlider: "/api/service/sliders",
        create: "/api/service/create",
        delete: "/api/service/delete"
    },
    testimony: {
        getSlider: "/api/testimony/sliders",
        get: "/api/testimony",
        create: "/api/testimony/create",
        delete: "/api/testimony"
    },
    user: {
        login: "/api/auth/login"
    },
    statistics: {
        get: "/api/statistics",
        update: "/api/statistics/update"
    }
};

export default Endpoint;