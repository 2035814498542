import React, { useEffect } from 'react';
import BoxItem from './BoxItem';
import { useDispatch, useSelector } from 'react-redux';
import Action from '../redux/Action';
import swal from 'sweetalert';
import Pagination from "../../../components/Pagination";

const Content = () => {
    const dispatch = useDispatch();

    const currentPage = useSelector(state => state.currentPage);
    const totalPages = useSelector(state => state.totalPages);
    const testimonies = useSelector(state => state.data);

    useEffect(() => {
        dispatch(Action.getData({ page: currentPage, pageSize: 6 }));
    }, []);

    const handleDeleteItem = async (testi) => {
        try {
            const deleted = await swal({
                icon: "warning",
                title: "Peringatan",
                text: "Apakah yakin untuk menghapus ?",
                buttons: ["Cancel", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false
            });
            if (deleted) {
                const id = testi.id;
                dispatch(Action.deleteItem(id));
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
        }
    };

    return (
        <div className="left_content">
            <div className="box_wrapper">
                {
                    testimonies.map(testi => (
                        <BoxItem
                            key={testi.id}
                            filename={testi.filename}
                            onDelete={() => handleDeleteItem(testi)} />
                    ))
                }
            </div>
            {
                testimonies.length > 0 ? (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPrev={() => {
                            dispatch(Action.handlePrev(currentPage));
                        }}
                        onNext={() => {
                            dispatch(Action.handleNext(currentPage));
                        }} />) : ""
            }
        </div>
    );
};

export default Content;