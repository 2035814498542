import React from 'react';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import { Provider } from 'react-redux';

const Layout = ({ children, store }) => {
    return (
        <>
            <Header />
            <NavBar />
            <Provider store={store}>
                {children}
            </Provider>
        </>
    );
};

export default Layout;