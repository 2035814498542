import React from 'react';
import Content from './components/Content';
import FormInput from './components/FormInput';
import StoreHelper from "../../helper/ConfigureCustomStore";
import Layout from '../Layout';
import reducerSlice from "./redux/Reducer";

const MainComponent = () => {
  return (
    <div className="container_admin">
      <div className='body_content'>
        <Content />
        <FormInput />
      </div>
    </div>
  );
};

const Service = () => {
  const store = StoreHelper.generateStore(reducerSlice);
  return (
    <Layout store={store}>
      <MainComponent />
    </Layout>
  );
};

export default Service;