import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
    name: "ProductReducer",
    initialState: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalData: 0,
        form: {
            id: 0,
            name: "",
            price: 0,
            promoPrice: 0,
            linkGumroad: "",
            images: []
        },
        filter: {
            searchBy: "name",
            searchValue: "",
            sortBy: "name",
            sortAsc: true,
            showInactive: false,
            minPrice: 0,
            maxPrice: 100
        },
        inputImage: null,
        showPopup: false,
        selectedProduct: {},
        isLoading: false,
        mode: "add"
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalData: (state, action) => {
            state.totalData = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setForm: (state, action) => {
            state.form = action.payload;
        },
        setInputImage: (state, action) => {
            state.inputImage = action.payload;
        },
        setShowPopup: (state) => {
            state.showPopup = !state.showPopup;
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
    }
});

export const {
    setData,
    setCurrentPage,
    setTotalPages,
    setTotalData,
    setForm,
    setInputImage,
    setShowPopup,
    setSelectedProduct,
    setLoading,
    setFilter,
    setMode
} = reducerSlice.actions;

export default reducerSlice.reducer;