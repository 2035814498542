import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import GeneralUtility from "../../../helper/GeneralUtility";
import ShowHideStatus from './ShowHideStatus';
import EditProduct from './EditProduct';

const STATIC_IMAGE = process.env.REACT_APP_IMAGE_URL;
const SERVER_IMAGE = process.env.REACT_APP_SERVER_URL;

const BoxItem = ({ onDelete, onDetail, product }) => {
    return (
        <div className="box_item">
            <div className='slider'>
                {product.active === 0 ?
                    <span className='inactive_label'>Inactive</span>
                    : ""}
                {
                    product.images.length <= 1 ? (
                        <img src={`${SERVER_IMAGE}/products/${product.images[0]}`} alt="" style={{ width: "100%", objectFit: "cover", height: "100%" }} />
                    ) : (
                        <Splide
                            aria-label=""
                            options={{
                                rewind: true,
                                width: "100%",
                                height: '244px'
                            }}>
                            {product.images.map((p, index) => (
                                <SplideSlide key={index}>
                                    <img src={`${SERVER_IMAGE}/products/${p}`} alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                </SplideSlide>
                            ))}
                        </Splide>
                    )
                }
            </div>
            <p className="title_product" style={{ width: "100%" }}>
                {GeneralUtility.cutString(product.name.toUpperCase(), 25)}
            </p>
            <div className="icons" style={{ width: "100%", marginTop: "10px" }}>
                <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                    {product.promo_price ?
                        <span style={{ marginRight: "10px", fontSize: "12px", color: "red", textDecoration: "line-through" }}>${product.promo_price}</span>
                        : ""}
                    <span>${product.price}</span>
                </div>
                <div>
                    <img src={STATIC_IMAGE + "/info.svg"} className="info" alt="" onClick={() => onDetail()} />
                    <ShowHideStatus productId={product.id} active={product.active} />
                    <EditProduct productId={product.id} />
                    <img src={STATIC_IMAGE + "/trash2.svg"} className="trash" alt="" onClick={() => onDelete()} />
                </div>
            </div>
        </div>
    );
};

export default BoxItem;