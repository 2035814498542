import axios from "axios";
import Endpoint from "../../../service/EndPoint";
import RouteName from "../../../service/RouteName";
import { setForm, setLoadingOff, setLoadingOn } from "./Reducer";
import swal from "sweetalert";
import Cookies from "js-cookie";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const login = (payload, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingOn());
            const reply = await axios.post(SERVER_URL + Endpoint.user.login, payload);
            const response = reply.data;
            if (response.success) {
                const token = response.data.token;
                Cookies.set('token', token, { expires: 1 });

                navigate(RouteName.service, { replace: true });
                dispatch(setLoadingOff());
                dispatch(setForm({ username: null, password: null }));
            }
        } catch (error) {
            const message = error.response.data.message;
            const isSwal = await swal({
                icon: "error",
                title: "Gagal",
                text: message,
                closeOnClickOutside: false
            });
            if (isSwal) {
                dispatch(setLoadingOff());
            }
        }
    };
};

const logout = async (navigate) => {
    const deleted = await swal({
        icon: "warning",
        title: "Peringatan",
        text: "Apakah yakin untuk logout ?",
        buttons: ["Cancel", "Yes"],
        dangerMode: true,
        closeOnClickOutside: false
    });

    if (deleted) {
        Cookies.remove('token');
        navigate(RouteName.login, { replace: true });
    }
};

const Action = {
    login,
    logout
};

export default Action;