import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../redux/Reducer";
import Action from "../redux/Action";
import { useState } from "react";
import FilterProduct from "./FilterProduct";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const FormInput = () => {
    const dispatch = useDispatch();

    const form = useSelector(state => state.form);
    const mode = useSelector(state => state.mode);
    const isLoading = useSelector(state => state.isLoading);
    const [images, setImages] = useState(null);

    const handleChangeInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        dispatch(setForm({ ...form, [name]: value }));
    };
    return (
        <div className="right_content">
            <div className="wrapper_right">
                <form encType="multipart/form-data" onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData();
                    formData.append("name", form.name);
                    formData.append("price", parseFloat(form.price));
                    formData.append("promo_price", parseFloat(form.promoPrice));
                    formData.append("link", form.linkGumroad);

                    if (images) {
                        images.forEach(file => {
                            formData.append('images', file);
                        });
                    }

                    if (mode === "add") {
                        dispatch(Action.createItem(formData));
                    } else {
                        formData.append("productId", form.id);
                        dispatch(Action.updateItem(formData));
                    }
                }}>
                    <label htmlFor="name">
                        Product Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={form.name}
                        onChange={(e) => handleChangeInput(e)} />
                    <label htmlFor="images">
                        Picture
                    </label>
                    <input
                        type="file"
                        id="images"
                        name="images"
                        multiple
                        onChange={(e) => {
                            const files = Array.from(e.target.files);
                            setImages(files);
                        }}
                        accept=".jpg, .png" />
                    {mode === "edit" ?
                        <div className="wrapper_filename">
                            <span>
                                CURRENT IMAGES
                            </span>
                            {
                                form.images.map((img, i) => (
                                    <span key={i}>
                                        - {img.toUpperCase()}
                                    </span>
                                ))
                            }
                        </div>
                        : ""}
                    <label htmlFor="price">
                        Price
                    </label>
                    <input
                        type="number"
                        id="price"
                        name="price"
                        value={form.price}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => handleChangeInput(e)} />
                    <label htmlFor="promoPrice">
                        Promo Price
                    </label>
                    <input
                        type="number"
                        id="promoPrice"
                        name="promoPrice"
                        value={form.promoPrice}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => handleChangeInput(e)} />
                    <label htmlFor="linkGumroad">
                        Link Gumroad
                    </label>
                    <input
                        type="text"
                        id="linkGumroad"
                        name="linkGumroad"
                        value={form.linkGumroad}
                        onChange={(e) => handleChangeInput(e)} />
                    {mode === "add" ?
                        <button
                            type="submit"
                            className={(!images) || form.name === "" ||
                                form.price === 0 || form.linkGumroad === "" ||
                                isLoading ? "disabled" : ""}
                            disabled={(!images) || form.name === "" ||
                                form.price === 0 || form.linkGumroad === "" ||
                                isLoading}>
                            {
                                isLoading ?
                                    <img className='loading_gif' src={IMAGE_URL + "/loading.svg"} alt="" />
                                    : "SUBMIT"
                            }
                        </button>
                        :
                        <button
                            type="submit"
                            className={form.name === "" ||
                                form.price === 0 || form.linkGumroad === "" ||
                                isLoading ? "disabled" : ""}
                            disabled={form.name === "" ||
                                form.price === 0 || form.linkGumroad === "" ||
                                isLoading}>
                            {
                                isLoading ?
                                    <img className='loading_gif' src={IMAGE_URL + "/loading.svg"} alt="" />
                                    : "SAVE"
                            }
                        </button>
                    }

                </form>
                <div className="divider"></div>
                <FilterProduct />
            </div>
        </div>
    );
};

export default FormInput;