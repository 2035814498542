import { createSlice } from "@reduxjs/toolkit";

export const ReducerSlice = createSlice({
    name: "AppReducer",
    initialState: {
        isLoading: false,
        form: {
            username: null,
            password: null
        }
    },
    reducers: {
        setLoadingOn: (state) => {
            state.isLoading = true;
        },
        setLoadingOff: (state) => {
            state.isLoading = false;
        },
        setForm: (state, action) => {
            state.form = action.payload;
        }
    }
});

export const {
    setLoadingOn,
    setLoadingOff,
    setForm
} = ReducerSlice.actions;

export default ReducerSlice.reducer;