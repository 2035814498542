import { Route, Routes, Navigate } from 'react-router-dom';
import NotFound from "../not_found/NotFound";
import Login from "../auth/Login";
import PrivateRoute from "../../helper/PrivateRoute";
import Service from '../service/Service';
import Testimony from '../testimony/Testimony';
import Product from '../product/Product';
import Statistics from '../statistics/Statistics';
import RouteName from '../../service/RouteName';
import StoreHelper from '../../helper/ConfigureCustomStore';
import { Provider } from 'react-redux';
import reducerSlice from './redux/Reducer';

const MainComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={RouteName.login} />} />
      <Route path="*" element={<NotFound />} />
      <Route path={RouteName.login} element={
        <Login />
      } />
      <Route path={RouteName.service} element={
        <PrivateRoute>
          <Service />
        </PrivateRoute>
      } />
      <Route path={RouteName.testimony} element={
        <PrivateRoute>
          <Testimony />
        </PrivateRoute>
      } />
      <Route path={RouteName.product} element={
        <PrivateRoute>
          <Product />
        </PrivateRoute>
      } />
      <Route path={RouteName.statistics} element={
        <PrivateRoute>
          <Statistics />
        </PrivateRoute>
      } />
    </Routes>
  );
};

function App() {
  const store = StoreHelper.generateStore(reducerSlice);
  return (
    <Provider store={store}>
      <MainComponent />
    </Provider >
  );
}

export default App;
