import { createSlice } from "@reduxjs/toolkit";

export const ReducerSlice = createSlice({
    name: "TestimonyReducer",
    initialState: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalData: 0,
        isLoading: false
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalData: (state, action) => {
            state.totalData = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
});

export const {
    setData,
    setCurrentPage,
    setTotalData,
    setTotalPages,
    setLoading
} = ReducerSlice.actions;

export default ReducerSlice.reducer;