import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../redux/Reducer';
import Action from '../redux/Action';

const FilterProduct = () => {
    const dispatch = useDispatch();
    const filter = useSelector(state => state.filter);
    const searchValue = filter.searchValue;
    const showInactive = filter.showInactive;
    const minPrice = filter.minPrice;
    const maxPrice = filter.maxPrice;
    const sortAsc = filter.sortAsc ? "asc" : "desc";

    useEffect(() => {
        dispatch(Action.getData({ page: 0, pageSize: 6, filter }));
    }, [
        filter.sortAsc,
        filter.showInactive,
        filter.minPrice,
        filter.maxPrice
    ]);

    return (
        <>
            <label htmlFor="searchProduct">Search Product Name</label>
            <input
                type="text"
                id="search"
                name="search"
                value={searchValue}
                onFocus={(e) => {
                    e.target.select();
                }}
                onChange={(e) => {
                    const value = e.target.value;
                    dispatch(setFilter({ ...filter, searchValue: value }));
                }}
                onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                        dispatch(Action.getData({ page: 0, pageSize: 6, filter }));
                    }
                }}
            />
            <label htmlFor="minPrice">Min Price - $</label>
            <input
                type="number"
                id="minPrice"
                name="minPrice"
                value={minPrice}
                onFocus={(e) => {
                    e.target.select();
                }}
                onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    dispatch(setFilter({ ...filter, minPrice: value }));
                }}
            />
            <label htmlFor="maxPrice">Max Price - $</label>
            <input
                type="number"
                id="maxPrice"
                name="maxPrice"
                value={maxPrice}
                onFocus={(e) => {
                    e.target.select();
                }}
                onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    dispatch(setFilter({ ...filter, maxPrice: value }));
                }}
            />
            <label htmlFor="filterProductSort">Sort Name</label>
            <select
                id="filterProductSort"
                name="filterProductSort"
                value={sortAsc}
                onChange={(e) => {
                    const value = e.target.value;
                    const sortAsc = value === "asc";
                    dispatch(setFilter({ ...filter, sortAsc }));
                }}>
                <option value="asc">A - Z</option>
                <option value="desc">Z - A</option>
            </select>

            <label htmlFor="">Product Status</label>
            <div className="wrapperProductStatus">
                <input
                    checked={!showInactive}
                    onChange={(e) => {
                        dispatch(setFilter({ ...filter, showInactive: false }));
                    }}
                    type="radio"
                    id="productStatusActive"
                    name="productStatus"
                    value="" />
                <label htmlFor="productStatusActive">Active</label>
                <input
                    checked={showInactive}
                    onChange={(e) => {
                        dispatch(setFilter({ ...filter, showInactive: true }));
                    }}
                    type="radio"
                    id="productStatusInactive"
                    name="productStatus"
                    value="" />
                <label htmlFor="productStatusInactive">Inactive</label>
            </div>
        </>
    );
};

export default FilterProduct;