import React, { useEffect } from 'react';
import Pagination from '../../../components/Pagination';
import Action from '../redux/Action';
import BoxItem from './BoxItem';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { setSelectedProduct, setShowPopup } from '../redux/Reducer';
import Popup from '../../../components/Popup';

const STATIC_IMAGE = process.env.REACT_APP_IMAGE_URL;
const Content = () => {
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.currentPage);
    const totalPages = useSelector(state => state.totalPages);
    const products = useSelector(state => state.data);
    const showPopup = useSelector(state => state.showPopup);
    const selectedProduct = useSelector(state => state.selectedProduct);
    const filter = useSelector(state => state.filter);

    useEffect(() => {
        dispatch(Action.getData({ page: currentPage, pageSize: 6, filter }));
    }, []);

    const handleDeleteItem = async (product) => {
        try {
            const deleted = await swal({
                icon: "warning",
                title: "Peringatan",
                text: "Apakah yakin untuk menghapus ?",
                buttons: ["Cancel", "Yes"],
                dangerMode: true,
                closeOnClickOutside: false
            });
            if (deleted) {
                const id = product.id;
                dispatch(Action.deleteItem(id));
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
        }
    };

    return (
        <>
            <div className="left_content">
                <div className="box_wrapper">
                    {
                        products.map((product) => (
                            <BoxItem
                                key={product.id}
                                product={product}
                                onDetail={() => {
                                    dispatch(setShowPopup());
                                    dispatch(setSelectedProduct(product));
                                }}
                                onDelete={() => handleDeleteItem(product)} />
                        ))
                    }
                </div>
                {
                    products.length > 0 ? (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPrev={() => {
                                dispatch(Action.handlePrev(currentPage, filter));
                            }}
                            onNext={() => {
                                dispatch(Action.handleNext(currentPage, filter));
                            }} />) : ""
                }
            </div>
            {
                showPopup ? (
                    <Popup>
                        <div className='wrapper_detail_product'>
                            <img
                                src={STATIC_IMAGE + "/close.svg"}
                                className='close_popup'
                                onClick={() => {
                                    dispatch(setShowPopup());
                                    dispatch(setSelectedProduct({}));
                                }} />
                            <span>Product Name</span>
                            <h5>{selectedProduct.name}</h5>
                            <span>Price</span>
                            <h5>{selectedProduct.price}</h5>
                            <span>Promo Price</span>
                            <h5>{selectedProduct.promo_price ?? 0}</h5>
                            <span>Link Gumroad</span>
                            <h5>{selectedProduct.link}</h5>
                        </div>
                    </Popup>
                ) : ""
            }
        </>
    );
};

export default Content;