import React from 'react';

const STATIC_IMAGE = process.env.REACT_APP_IMAGE_URL;
const SERVER_IMAGE = process.env.REACT_APP_SERVER_URL;

const BoxItem = ({ onDelete, filename }) => {
    return (
        <div className="box_item">
            <div className='slider'>
                <img
                    src={`${SERVER_IMAGE}/testimonies/${filename}`}
                    alt=""
                    style={{ width: "100%", objectFit: "cover", height: "100%" }} />
            </div>
            <div className="desc_bar">
                <div></div>
                <img src={STATIC_IMAGE + "/trash2.svg"} className="trash" alt="" onClick={() => onDelete()} />
            </div>
        </div>
    );
};

export default BoxItem;
