import StoreHelper from '../../helper/ConfigureCustomStore';
import reducerSlice from "./redux/Reducer";
import Layout from '../Layout';
import Content from './components/Content';
import FormInput from './components/FormInput';

const MainComponent = () => {
    return (
        <div className="container_admin">
            <div className='body_content'>
                <Content />
                <FormInput />
            </div>
        </div>
    );
};

const Testimony = () => {
    const store = StoreHelper.generateStore(reducerSlice);
    return (
        <Layout store={store}>
            <MainComponent />
        </Layout>
    );
};

export default Testimony;