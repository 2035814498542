import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Action from "../redux/Action";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const FormInput = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.isLoading);
    const [image, setImage] = useState(null);

    return (
        <div className="right_content">
            <div className="wrapper_right">
                <form encType='multipart/form-data' onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData();
                    formData.append("image", image);
                    dispatch(Action.createItem(formData, setImage));
                }}>

                    <label htmlFor="picture">Picture</label>
                    <input
                        type="file"
                        id="picture"
                        name="picture"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setImage(file);
                        }}
                        accept=".jpg, .png" />

                    <button
                        type="submit"
                        className={!image || isLoading ? "disabled" : ""}
                        disabled={!image || isLoading}>
                        {isLoading ?
                            <img className='loading_gif' src={IMAGE_URL + "/loading.svg"} alt="" />
                            : "SUBMIT"}
                    </button>
                </form>
            </div>
        </div >
    );
};

export default FormInput;