import React from 'react';

const Pagination = ({ onPrev, onNext, currentPage, totalPages }) => {
    return (
        <div className='pagination_wrapper'>
            <button
                className={currentPage <= 0 ? "disabled" : ""}
                disabled={currentPage <= 0}
                onClick={() => onPrev()}>
                &laquo;
            </button>
            <div className='box_number'>
                {currentPage + 1}
            </div>
            <button
                disabled={currentPage >= totalPages - 1}
                className={currentPage >= totalPages - 1 ? "disabled" : ""}
                onClick={() => onNext()}>
                &raquo;
            </button>
            <p>
                of Total Page {totalPages}
            </p>
        </div>
    );
};

export default Pagination;