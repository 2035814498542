import StoreHelper from "../../helper/ConfigureCustomStore";
import Layout from "../Layout";
import Content from "./components/Content";
import FormInput from "./components/FormInput";
import reducerSlice from "./redux/Reducer";

const MainComponent = () => {
    return (
        <div className="container_admin">
            <div className='body_content'>
                <Content />
                <FormInput />
            </div>
        </div>
    );
};

const Product = () => {
    const ignoredActionPaths = ["ProductReducer/setInputImage"];
    const store = StoreHelper.generateStore(reducerSlice, ignoredActionPaths);
    return (
        <Layout store={store}>
            <MainComponent />
        </Layout>
    );
};

export default Product;