import swal from "sweetalert";
import Action from "../redux/Action";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";

const STATIC_IMAGE = process.env.REACT_APP_IMAGE_URL;
const ShowHideStatus = ({ productId, active }) => {
    const dispatch = useDispatch();
    const filter = useSelector(state => state.filter);

    // useEffect(() => {
    //     dispatch(Action.getData({ page: 0, pageSize: 6, filter }));
    // }, [filter.showInactive]);

    const handleStatusProduct = async (type) => {
        let text = "";
        if (type === "hide") {
            text = "Apakah yakin untuk menon-aktifkan product ini ?";
        } else {
            text = "Apakah yakin untuk meng-aktifkan product ini ?";
        }

        const isSwal = await swal({
            icon: "warning",
            title: "Peringatan",
            text,
            buttons: ["Cancel", "Yes"],
            dangerMode: true,
            closeOnClickOutside: false
        });
        if (isSwal) {
            const payload = {
                productId: productId,
                status: active === 0 ? 1 : 0
            };
            dispatch(Action.changeStatus(payload, filter));
        }
    };

    return (
        <>
            {
                active ? (
                    <img src={STATIC_IMAGE + "/close-eye.svg"} className="eyeStatus" alt="" onClick={(e) => handleStatusProduct("hide")} />
                ) : (
                    <img src={STATIC_IMAGE + "/open-eye.svg"} className="eyeStatus" alt="" onClick={(e) => handleStatusProduct("unhide")} />
                )
            }
        </>
    );
};

export default ShowHideStatus;