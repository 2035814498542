import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteName from '../service/RouteName';
import Action from '../pages/app/redux/Action';

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const Menu = [
    { to: RouteName.service, placeholder: "SERVICE" },
    { to: RouteName.testimony, placeholder: "TESTIMONY" },
    { to: RouteName.product, placeholder: "PRODUCT" },
    { to: RouteName.statistics, placeholder: "STATISTICS" },
];

const Link = ({ to, children, navigate }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    return (
        <span
            className={to === currentPath ? "active" : ""}
            onClick={() => navigate(to, { replace: true })}>
            {children}
        </span>
    );
};

const NavBar = () => {
    const navigate = useNavigate();

    return (
        <div className="menu_context">
            <ul>
                {
                    Menu.map(m => {
                        return (
                            <li key={m.to}>
                                <Link to={m.to} navigate={navigate}>
                                    {m.placeholder}
                                </Link>
                            </li>
                        );
                    })
                }
                <li>
                    <img
                        className='button_logout'
                        src={IMAGE_URL + '/power.svg'}
                        alt=''
                        onClick={async () => {
                            await Action.logout(navigate);
                        }} />
                </li>
            </ul>
        </div>
    );
};

export default NavBar;