import { useEffect } from "react";
import StoreHelper from "../../helper/ConfigureCustomStore";
import Layout from '../Layout';
import { useDispatch, useSelector } from "react-redux";
import Action from "./redux/Action";
import reducerSlice, { setData } from "./redux/Reducer";

const MainComponent = () => {
  const dispatch = useDispatch();
  const stats = useSelector(state => state.data);

  useEffect(() => {
    dispatch(Action.getData());
  }, []);

  const handleChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    dispatch(setData({ ...stats, [name]: value }));
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      const payload = {};
      for (const key in stats) {
        if (stats.hasOwnProperty(key)) {
          payload[key] = parseFloat(stats[key]);
        }
      }
      dispatch(Action.updateData(payload));
    }}>
      <div className="wrapper_stats">
        <div className="box_stats">
          <input
            type="number"
            className="input_stats"
            name="projects"
            value={stats.projects}
            onFocus={(e) => e.target.select()}
            onChange={(e) => handleChangeInput(e)} />
          <p className="title_stats">Projects</p>
        </div>
        <div className="box_stats">
          <input
            type="number"
            className="input_stats"
            name="clients"
            value={stats.clients}
            onFocus={(e) => e.target.select()}
            onChange={(e) => handleChangeInput(e)} />
          <p className="title_stats">Clients</p>
        </div>
        <div className="box_stats">
          <input
            type="number"
            className="input_stats"
            name="ratings"
            value={stats.ratings}
            onFocus={(e) => e.target.select()}
            onChange={(e) => handleChangeInput(e)} />
          <p className="title_stats">Ratings</p>
        </div>
        <div className="box_stats">
          <input
            type="number"
            className="input_stats"
            name="reviews"
            value={stats.reviews}
            onFocus={(e) => e.target.select()}
            onChange={(e) => handleChangeInput(e)} />
          <p className="title_stats">Reviews</p>
        </div>
        <button
          className={
            stats.projects === "" || !stats.projects ||
              stats.clients === "" || !stats.clients ||
              stats.ratings === "" || !stats.ratings ||
              stats.reviews === "" || !stats.reviews ? "disabled" : ""
          }
          disabled={
            stats.projects === "" || !stats.projects ||
            stats.clients === "" || !stats.clients ||
            stats.ratings === "" || !stats.ratings ||
            stats.reviews === "" || !stats.reviews
          }
          type="submit">SUBMIT</button>
      </div>
    </form>
  );
};

const Statistics = () => {
  const store = StoreHelper.generateStore(reducerSlice);
  return (
    <Layout store={store}>
      <MainComponent />
    </Layout>
  );
};

export default Statistics;