import React from 'react';
import swal from 'sweetalert';
import Action from '../redux/Action';
import { useDispatch } from 'react-redux';

const STATIC_IMAGE = process.env.REACT_APP_IMAGE_URL;
const EditProduct = ({ productId }) => {
    const dispatch = useDispatch();
    return (
        <img
            src={STATIC_IMAGE + "/pencil.svg"}
            className="sunting"
            alt=""
            onClick={async () => {
                const isSwal = await swal({
                    icon: "info",
                    title: "Info",
                    text: `Apakah anda ingin melakukan perubahan terhadap data ini ?`,
                    buttons: ["Cancel", "Yes"],
                    closeOnClickOutside: false
                });
                if (isSwal) {
                    dispatch(Action.getDetailData(productId));
                }
            }} />
    );
};

export default EditProduct;