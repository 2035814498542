import React from 'react';

const Popup = ({ children }) => {
    return (
        <>
            <div className='overlay_popup'></div>
            <div className="content_popup">
                {children}
            </div>
        </>
    );
};

export default Popup;